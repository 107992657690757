import React, { Component } from 'react';
import FadeIn from 'react-fade-in';
import 'font-awesome/css/font-awesome.min.css';
import '../App.css';

export default class Experience extends Component {
    constructor(props){
        super(props);
    }

    render() {
        return (
            <div className="main-headings">
            <FadeIn transitionDuration={1000}>
                <h3 className="name">
                    WORK EXPERIENCE
                </h3><br/>
                <br/>
                <div className="heading-content">
                    <h5>Full-Stack Engineer Co-op</h5>
                    <h5>FORM Athletica</h5>
                    <i>Vancouver, BC, Canada</i>
                    <br/>
                    <br/>
                    <h5>Software Engineer Co-op</h5>
                    <h5>Bandai Namco Studios</h5>
                    <i>Vancouver, BC, Canada</i>
                    <br/>
                    <br/>
                </div>
            </FadeIn>
            </div>
        );
    }
}