import ReactGA from 'react-ga';
import "bootstrap/dist/css/bootstrap.min.css";
import 'font-awesome/css/font-awesome.min.css';
import Typing from 'react-typing-animation';
import './App.css';
import './index.css'
import React, { Component } from 'react';
import Profile from "./Components/Profile";
import About from "./Components/About";
import Projects from "./Components/Projects";
import Achievements from "./Components/Achievements";
import Experience from "./Components/Experience";
import Contact from "./Components/Contact";
import Education from "./Components/Education";
import Top from "./Components/Top";
import MetaTags from 'react-meta-tags';


ReactGA.initialize('UA-169299648-1');
ReactGA.pageview(window.location.pathname + window.location.search);

export default class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      displayHelloWorld: true,
      
      displayHomePage: false,
      displayAbout: false,
      displayProjects: false,
      displayAchievements: false,
      displayExperience: false,
      displayContact: false,
      displayEducation: false,
    };
  }

  toggleHomePage = () => {
    this.setState({
      displayHomePage: true,
      displayHelloWorld: false,
      displayAbout: false,
      displayProjects: false,
      displayAchievements: false,
      displayExperience: false,
      displayContact: false,
      displayEducation: false,
    });
  }

  toggleAbout = () => {
    this.setState({
      displayHomePage: false,
      displayHelloWorld: false,
      displayAbout: true,
      displayProjects: false,
      displayAchievements: false,
      displayExperience: false,
      displayContact: false,
      displayEducation: false,
    });
  }

  toggleProjects = () => {
    this.setState({
      displayHomePage: false,
      displayHelloWorld: false,
      displayAbout: false,
      displayProjects: true,
      displayAchievements: false,
      displayExperience: false,
      displayContact: false,
      displayEducation: false,
    });
  }

  toggleExperience = () => {
    console.log("Changing experience")
    this.setState({
      displayHomePage: false,
      displayHelloWorld: false,
      displayAbout: false,
      displayProjects: false,
      displayAchievements: false,
      displayExperience: true,
      displayContact: false,
      displayEducation: false,
    });
  }

  toggleContact = () => {
    this.setState({
      displayHomePage: false,
      displayHelloWorld: false,
      displayAbout: false,
      displayProjects: false,
      displayAchievements: false,
      displayExperience: false,
      displayContact: true,
      displayEducation: false,
    });
  }

  toggleAchievements = () => {
    this.setState({
      displayHomePage: false,
      displayHelloWorld: false,
      displayAbout: false,
      displayProjects: false,
      displayAchievements: true,
      displayExperience: false,
      displayContact: false,
      displayEducation: false,
    });
  }

  toggleHelloWorld = () => {
    this.setState({
      displayHelloWorld: true,
      displayHomePage: false,
      displayAbout: false,
      displayProjects: false,
      displayAchievements: false,
      displayExperience: false,
      displayContact: false,
      displayEducation: false,
    });
  }

  toggleEducation = () => {
    this.setState({
      displayHelloWorld: false,
      displayHomePage: false,
      displayAbout: false,
      displayProjects: false,
      displayAchievements: false,
      displayExperience: false,
      displayContact: false,
      displayEducation: true,
    });
  }

  /**
 * Keys used: 
 * 1: profile page
 * 2: about
 * 3: projects
 * 4: education
 * 5: achievements
 * 6: experience
 * 7: contact
 */

  changePage = (showCollapsedIcons, currentPage) => {

    if (showCollapsedIcons) {
      // need to display the page
      this.setState({
        displayHelloWorld: false,
        displayHomePage: currentPage == 1 ? true : false,
        displayAbout: currentPage == 2 ? true : false,
        displayProjects: currentPage == 3 ? true : false,
        displayEducation: currentPage == 4 ? true : false,
        displayAchievements: currentPage == 5 ? true : false,
        displayExperience: currentPage == 6 ? true : false,
        displayContact: currentPage == 7 ? true : false,
      });
    } else {
      // hide everything
      this.setState({
        displayHelloWorld: false,
        displayHomePage: false,
        displayAbout: false,
        displayProjects: false,
        displayAchievements: false,
        displayExperience: false,
        displayContact: false,
        displayEducation: false,
      });
    }
  }

  render() {
    return (
      <div className="App">
        <MetaTags>
            <meta name="keywords" content="software developer, software engineer, react, node, Mayank, Aggarwal, mayank, aggarwal, mayankaggarwal, mayank-agg, aggarwalmayank" />
        </MetaTags>
        {! this.state.displayHelloWorld && 
        <Top 
          toggleAbout={this.toggleAbout}
          toggleHomePage={this.toggleHomePage}
          toggleExperience={this.toggleExperience}
          toggleProjects={this.toggleProjects}
          toggleContact={this.toggleContact}
          toggleAchievements={this.toggleAchievements}
          toggleEducation={this.toggleEducation}
          changePage={this.changePage}
        />}
          {this.state.displayHelloWorld &&
          <header className="App-header">
          <Typing speed={100} onFinishedTyping={this.toggleHomePage}>
            <span className="name helloWorld">Hello, World!</span>
            <Typing.Backspace count={20} speed={100} delay={800}/>
          </Typing>
          </header>}
          {this.state.displayHomePage &&
          <header className="App-header"> 
            <Profile/>
          </header>
          }
          {this.state.displayAbout && 
            <About/>
          }
          {this.state.displayProjects && 
            <Projects/>
          }
          {this.state.displayAchievements && 
            <Achievements/>
          }
          {this.state.displayExperience && 
            <Experience/>
          }
          {this.state.displayContact && 
            <Contact/>
          }
          {this.state.displayEducation && 
            <Education />
          }
    {this.state.displayHomePage && <div class="footer">
      <p>Designed and Developed by Mayank Aggarwal</p>
      <p>Copyright &copy; 2021</p>
    </div>}
    </div>
    );
  }
}

