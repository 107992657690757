


import React, { Component } from 'react';
import FadeIn from 'react-fade-in';
import Typing from 'react-typing-animation';
import 'font-awesome/css/font-awesome.min.css';
import '../App.css';

export default class TypingAnimation extends Component {
    constructor(props){
        super(props);
    }

    render() {
        return (
            <Typing speed={200}>
                    <span className="name helloWorld">{this.props.message}</span>
                    <Typing.Backspace count={20} speed={100} delay={500}/>
            </Typing>
        );
    }
}