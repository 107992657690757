import ReactGA from 'react-ga';
import React, { Component } from 'react';
import FadeIn from 'react-fade-in';
import 'font-awesome/css/font-awesome.min.css';
import '../App.css';

ReactGA.initialize('UA-169299648-1');
ReactGA.pageview(window.location.pathname + window.location.search);

export default class About extends Component {
    constructor(props){
        super(props);

        this.state= {
            heading: true,
        }
    }
    
    // componentDidMount = () => {
    //     setTimeout(() => {
    //         this.setState({heading: false})
    //     }, 1500);
    // }

    render() {
        return (
            <div className="main-headings">
                {this.state.heading && <div><FadeIn transitionDuration={1000}>
                    <h3 className="headings">
                    ABOUT</h3><br/><br/>
                    <p class="content">A Computer Science graduate
                    with more than three years of software development experience from
                    internships, personal projects, and group assignments. Also, an aspiring entrepreneur who has experience working in many 
                    cross-functional teams with the goal of creating successful entrepreneurship projects. 
                    These projects were showcased at <a href="https://bsb-cc-web.bus.sfu.ca/event/opportunity-fest/" target="_blank" className="oppfest">SFU's Opportunity Fest</a> in 2021 and 2020, where we won <a href="https://bsb-cc-web.bus.sfu.ca/event/opportunity-fest/"  target="_blank" className="oppfest">1st place</a> in the Holistic Health category in 2021 and the <a href="https://bsb-cc-web.bus.sfu.ca/event/opportunity-fest/" target="_blank" className="oppfest">Entrepreneur's Choice Award</a> in 2020. </p>
                    </FadeIn>
                    <FadeIn transitionDuration={1000} delay={200}>
                    <br/>

                    <p class="content" id="review-head"> Mentor reviews from previous work experiences </p>
                    <br/><br/>
                    <div className="heading-content">
                        <p>"Mayank is a very strong software engineer with a bright future ahead of him. He is resourceful and a quick learner - he is able to tackle and solve many different types of issues that are thrown at him. "</p>
                        <i>Kevin Mann, Web and Mobile Team Lead, FORM Athletica</i>
                        <br/>
                        <br/>
                        <br/>
                        <p>"Mayank is a dependable employee with solid problem solving skills. He is very careful and cautious about his work, and has an 
                            exceptional work ethic. Would strongly recommend for any Software Engineering position"</p>
                        <i>Takashi Matsuno, Technical Lead, Bandai Namco Studios</i>
                        <br/>
                    </div>
                </FadeIn></div>}
            </div>
        );
    }
}