import ReactGA from 'react-ga';
import React, { Component } from 'react';
import FadeIn from 'react-fade-in';
import "bootstrap/dist/css/bootstrap.min.css";
import 'font-awesome/css/font-awesome.min.css';
import '../App.css';

ReactGA.initialize('UA-169299648-1');
ReactGA.pageview(window.location.pathname + window.location.search);

export default class Projects extends Component {
    constructor(props){
        super(props);

        this.state = {
            tab: 0
        }

        this.groupTab = React.createRef();
        this.indivTab = React.createRef();
    }


    defaultStyle = {
        backgroundColor: "dimgray",
    }

    changeTab = (tab) => {
        this.setState({
            tab: tab
        });

        if (tab == 0) {
            this.groupTab.current.style.backgroundColor = "dimgray";
            this.indivTab.current.style.backgroundColor = "#282c34";
        } else {
            this.indivTab.current.style.backgroundColor = "dimgray";
            this.groupTab.current.style.backgroundColor = "#282c34";
        }
    }

    render() {
        return (
            <div className="main-headings">
                <div class="project-heading">
                <FadeIn transitionDuration={1000}>
                    <h3 className="headings">
                    PROJECTS
                </h3>
                <br/>
                <ul class="nav nav-pills mb-3 justify-content-center" role="tablist">
                    <li class="nav-item custom-tab">
                        <a class="nav-link" ref={this.groupTab} style={this.defaultStyle} onClick={() => this.changeTab(0)} href="#group" aria-selected={this.state.tab == 0}>Group</a>
                    </li>
                    <li class="nav-item custom-tab">
                        <a class="nav-link" ref={this.indivTab} onClick={() => this.changeTab(1)} data-toggle="pill" href="#individual" aria-selected={this.state.tab == 1}>Individual</a>
                    </li>
                </ul>
                </FadeIn>
                </div>
                {this.state.tab == 0 && <FadeIn transitionDuration={1000}><div class="row">
                    {/* <div class="col-sm-6">
                        <div class="card card-custom project">
                        <div class="card-body">
                            <h5 class="card-title">Elevate (April 2021)</h5>
                            <p class="card-text"><b>Tools used: </b>-------</p>
                            <p class="card-text">A smart resistance band attachment that can be used to measure the force exerted by the user, potentially usable in physical therapy to enhance rehabilitation. </p>
                            <p class="card-text">Acted as a gateway _________. Winner at OppFest 2021 - https://bsb-cc-web.bus.sfu.ca/event/opportunity-fest/</p>
                        </div>
                        <a href="https://github.com/mayank-agg/myBetterExperience" class="btn btn-primary checkitout">Check it out</a>
                        </div>
                    </div> */}
                    <div class="col-sm-6">
                        <div class="card card-custom project">
                        <div class="card-body">
                            <h5 class="card-title">myBetterExperience</h5>
                            <p class="card-text"><b>Tools used: </b>ReactJS, NodeJS with Express, Mongoose</p>
                            <p class="card-text">A Job Portal for campus recruitments. Co-developed in a team of 4 to potentially improve the current SFU's co-op hiring platform.</p>
                            <p class="card-text">Contributed to implementation of Profiles (Company and Users), Job postings/reviews, and File uploads.</p>
                        </div>
                        <a href="https://github.com/mayank-agg/myBetterExperience" class="btn btn-primary checkitout">Check it out</a>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="card card-custom project">
                        <div class="card-body project">
                            <h5 class="card-title">YASE (Yet Another Storage Engine)</h5>
                            <p class="card-text"><b>Tools used: </b>C++, G Tests</p>
                            <p class="card-text">A Database Engine supporting CRUD operations. Co-implemented in a team of 4.</p>
                            <p class="card-text">Contributed to implementation of Lock Manager, Log Manager, and Buffer Manager</p>
                        </div>
                        <a href="https://github.com/mayank-agg/myStorageEngine" class="btn btn-primary checkitout">Check it out</a>
                        </div>
                    </div>
                    <br/>
                    <br/>
                    <div class="col-sm-6">
                        <div class="card card-custom project">
                        <div class="card-body">
                            <h5 class="card-title">Shrug Web App</h5>
                            <p class="card-text"><b>Tools used: </b>Ruby on Rails, Postgres, HTML/CSS</p>
                            <p class="card-text">Co-developed an interactive web application that allows its nearby users to connect and create chat rooms.</p>
                            <p class="card-text">Contributed by implementing user profile, image uploads, and integrating the app with Google Maps API</p>
                        </div>
                        <a href="shrug.herokuapp.com" class="btn btn-primary checkitout">Check it out</a>
                        </div>
                    </div>
                </div></FadeIn>}
                {this.state.tab == 1 && <FadeIn transitionDuration={1000}><div class="row">
                    <div class="col-sm-6">
                        <div class="card card-custom project">
                        <div class="card-body">
                            <h5 class="card-title">Website - mayankagg.com</h5>
                            <p class="card-text"><b>Tools used: </b>React, NodeJS, Firebase</p>
                            <p class="card-text">Designed and Developed this website with React. Deployed using Firebase and added Google Analytics to gather statistical data. </p>
                        </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="card card-custom project">
                        <div class="card-body">
                            <h5 class="card-title">3D Tic-Tac-Toe</h5>
                            <p class="card-text"><b>Tools used: </b>NodeJS with Express, HTML5/CSS3/JavaScript with Bootstrap</p>
                            <p class="card-text">Developed a live game application that allows players to play 3D Tic-tac-toe against each other in real-time. Provided the users with the ability to chat while playing.</p>
                        </div>
                        <a href="https://github.com/mayank-agg/3d-tic-tac-toe" class="btn btn-primary checkitout">Check it out</a>
                        </div>
                    </div>
                    <br/>
                    <div class="col-sm-6">
                        <div class="card card-custom project">
                        <div class="card-body">
                            <h5 class="card-title">What To Cook? (In progress)</h5>
                            <p class="card-text"><b>Tools used: </b>Swift</p>
                            <p class="card-text">Developing an iOS app to potentially learn more about Swift and iOS app development. The app would allow users to enter a list of ingredients, based on which a delicious recipe would be generated that users can save and share with their friends.</p>
                        </div>
                        <a href="#" disabled={true} class="btn btn-secondary checkitout">Coming soon</a>
                        </div>
                    </div>
                </div></FadeIn> }
                
            </div>
        );
    }
}