import React, { Component } from 'react';
import FadeIn from 'react-fade-in';
import "bootstrap/dist/css/bootstrap.min.css";
import 'font-awesome/css/font-awesome.min.css';
import '../App.css';


/**
 * Keys used: 
 * 1: profile page
 * 2: about
 * 3: projects
 * 4: education
 * 5: achievements
 * 6: experience
 * 7: hello
 */
export default class Top extends Component {
    constructor(props){
        super(props);
        
        this.state = {
            show: false,
            currentPage: 1,
        };

        this.toggleMenu = this.toggleMenu.bind(this);
    }

    toggleMenu() {
        this.setState({
            show: ! this.state.show,
        });
        
        this.props.changePage(this.state.show, this.state.currentPage);
    }

    changePage = (currPage) => {

        this.setState({
            show: false,
            currentPage: currPage,
        });

        if (currPage == 1) {
            this.props.toggleHomePage();
        } else if (currPage == 2) {
            this.props.toggleAbout();
        } else if (currPage == 3) {
            this.props.toggleProjects();
        } else if (currPage == 4) {
            this.props.toggleEducation();
        } else if (currPage == 5) {
            this.props.toggleAchievements();
        } else if (currPage == 6) {
            this.props.toggleExperience();
        } else if (currPage == 7) {
            this.props.toggleContact();
        }
    }

    render() {
        const show = this.state.show ? "show" : "";

        return (
            <nav className="navbar navbar-expand-lg navbar-custom fixed-top navbar-dark">
                    {! this.state.show && <a className="navbar-brand custom-link" id="brand-logo" href="#" onClick={() => this.changePage(1)}>&lt;/&gt;</a>}

                    <button className="navbar-toggler mx-auto" type="button" onClick={this.toggleMenu} data-toggle="collapse" data-target="#navbarText">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <br/>
                    <br/>
                    <div className={"collapse navbar-collapse nav-centre-box " + show} id="navbarText">
                        {this.state.show && <FadeIn transitionDuration={1000}>
                        <ul className="navbar-nav mx-auto collapse-link">
                            <li className="nav-item">
                                <a className="custom-link nav-link" href="#" onClick={() => this.changePage(2)}>about</a>
                            </li>
                            <li className="nav-item">
                                <a className="custom-link nav-link" href="#" onClick={() => this.changePage(3)}>projects</a>
                            </li>
                            <li className="nav-item">
                                <a className="custom-link nav-link" href="#" onClick={() => this.changePage(4)}>education</a>
                            </li>
                            {/* <li className="nav-item">
                                <a className="custom-link nav-link" href="#" onClick={() => this.changePage(5)}>achievements</a>
                            </li>
                            <li className="nav-item">
                                <a className="custom-link nav-link" href="#" onClick={() => this.changePage(6)}>work experience</a>
                            </li> */}
                        </ul>
                        </FadeIn>}
                        {! this.state.show && 
                        <ul className="navbar-nav mx-auto">
                            <li className="nav-item">
                                <a className="custom-link nav-link" href="#" onClick={() => this.changePage(2)}>about</a>
                            </li>
                            <li className="nav-item">
                                <a className="custom-link nav-link" href="#" onClick={() => this.changePage(3)}>projects</a>
                            </li>
                            <li className="nav-item">
                                <a className="custom-link nav-link" href="#" onClick={() => this.changePage(4)}>education</a>
                            </li>
                            {/* <li className="nav-item">
                                <a className="custom-link nav-link" href="#" onClick={() => this.changePage(5)}>achievements</a>
                            </li>
                            <li className="nav-item">
                                <a className="custom-link nav-link" href="#" onClick={() => this.changePage(6)}>work experience</a>
                            </li> */}
                        </ul>}
                    </div>
                    {! this.state.show && <ul className="navbar-nav ml-auto">
                        <li className="nav-item">
                            <a className="custom-link nav-link" href="#" onClick={() => this.changePage(7)}>say hello</a>
                        </li>
                    </ul>}
            </nav>
        );
    }
}