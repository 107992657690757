import React, { Component } from 'react';
import FadeIn from 'react-fade-in';
import Typing from 'react-typing-animation';
import TypingAnimation from "./TypingAnimation";
import 'font-awesome/css/font-awesome.min.css';
import '../App.css';

export default class Contact extends Component {
    constructor(props){
        super(props);
    }

    render() {
        return (
            <div className="main-headings">
            <FadeIn transitionDuration={1000}>
                <p class="content contact">Have a question? Share an interest? Wanna work together?</p>
                <br/>
                <p class="content contact">Let's talk!</p>
                {/* <p><a href="mailto:maggarwa@sfu.ca" id="email-link" className="fa fa-at"><span class="content email">maggarwa@sfu.ca</span></a> | <a href="https://linkedin.com/in/magg" className="fa fa-linkedin"></a></p> */}
                <p class="content contact"><a class="email-id" href="mailto:maggarwal368@gmail.com" className="fa fa-envelope"></a> | <a href="https://linkedin.com/in/magg" className="fa fa-linkedin"></a></p>
            </FadeIn>
            </div>
        );
    }
}