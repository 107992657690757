import React, { Component } from 'react';
import logo from '../code.png';
import Typing from 'react-typing-animation';
import FadeIn from 'react-fade-in';
import 'font-awesome/css/font-awesome.min.css';
import '../App.css';

export default class Profile extends Component {
    constructor(props){
        super(props);
    }

    render() {
        return (
            <FadeIn transitionDuration={1000}>
                <h3 className="name name-head">
                    MAYANK AGGARWAL
                </h3>
                <br/>
                <h6 className="name">
                    programmer, space nerd, and enjoys <Typing speed={50} loop={true}>
                        <span className="name helloWorld">travel.</span>
                        <Typing.Backspace count={20} speed={30} delay={700}/>
                        <Typing.Speed ms={50}/>
                        <span className="name helloWorld">ice skating.</span>
                        <Typing.Backspace count={20} speed={30} delay={700}/>
                        <Typing.Speed ms={50}/>
                        <span className="name helloWorld">swimming.</span>
                        <Typing.Backspace count={20} speed={30} delay={700}/>
                        <Typing.Speed ms={50}/>
                        {/* <span className="name helloWorld">bowling.</span>
                        <Typing.Backspace count={20} speed={30} delay={700}/>
                        <Typing.Speed ms={50}/> */}
                        <span className="name helloWorld">hiking.</span>
                        <Typing.Backspace count={20} speed={30} delay={700}/>
                        <Typing.Speed ms={50}/>
                        <span className="name helloWorld">movies.</span>
                        <Typing.Backspace count={20} speed={30} delay={700}/>
                        <Typing.Speed ms={50}/>
                        <span className="name helloWorld">pizza.</span>
                        <Typing.Backspace count={20} speed={30} delay={700}/>
                        <Typing.Speed ms={50}/>
                        <span className="name helloWorld">bubble tea.</span>
                        <Typing.Backspace count={20} speed={30} delay={700}/>
                        <span className="name helloWorld">Kurzgesagt.</span>
                        <Typing.Backspace count={20} speed={30} delay={700}/>
                    </Typing>
                </h6>
                <br/>
                <h6 className="name">Vancouver, BC</h6>
                {/* <p><a href="https://linkedin.com/in/magg" className="fa fa-linkedin"></a> | <a href="https://github.com/mayank-agg" className="fa fa-github"></a></p> */}
                <br/>
                <br/>
                <p><a href="https://linkedin.com/in/magg" className="fa fa-linkedin"></a> | <a href="https://github.com/mayank-agg" className="fa fa-github"></a></p>
            </FadeIn>
        );
    }
}