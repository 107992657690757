import React, { Component } from 'react';
import FadeIn from 'react-fade-in';
import 'font-awesome/css/font-awesome.min.css';
import '../App.css';

export default class Achievements extends Component {
    constructor(props){
        super(props);
    }

    render() {
        return (
            <div className="main-headings">
                <FadeIn transitionDuration={1000}>
                    <h3 className="headings">
                    ACHIEVEMENTS
                </h3>
                <br/>
                <br/>
                <div className="heading-content">
                    <h5>Open Scholarship Recipient (2018)</h5>
                    <h5>Simon Fraser University</h5>
                    <i>Burnaby, BC, Canada</i>
                    <br/>
                    <br/>
                    <h5>Entrance Scholarship Winner (2017)</h5>
                    <h5>Simon Fraser University</h5>
                    <i>Burnaby, BC, Canada</i>
                    <br/>
                    <br/>
                    <h5>Winner of Entreprenur's Choice Awards (2020)</h5>
                    <h5>SFU OppFest</h5>
                    <i>Burnaby, BC, Canada</i>
                    <br/>
                    <br/>
                    <h5>Winner of Iceplex Awards in Regular Ads (2008)</h5>
                    <h5>Ryan International Group of Instiutions</h5>
                    <i>Mumbai, India</i>
                    <br/>
                    <br/>
                </div></FadeIn>
            </div>
        );
    }
}