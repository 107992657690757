import ReactGA from 'react-ga';
import React, { Component } from 'react';
import FadeIn from 'react-fade-in';
import 'font-awesome/css/font-awesome.min.css';
import '../App.css';

ReactGA.initialize('UA-169299648-1');
ReactGA.pageview(window.location.pathname + window.location.search);

export default class Education extends Component {
    constructor(props){
        super(props);
    }

    render() {
        return (
            <div className="main-headings">
                <FadeIn transitionDuration={1000}>
                    <h3 className="headings">
                    EDUCATION
                </h3><br/><br/>
                <div className="heading-content">
                    <h5>Bachelor of Science (B.Sc.) in Computing Science &amp; Certificate in Innovation and Entrepreneurship</h5>
                    <br/>
                    <h5>Simon Fraser University</h5>
                    <h6>Burnaby, BC, Canada</h6>
                    <br/>
                    <br/>
                    <p class="coursework-edu">Coursework: Algorithms and Data Structures, Operating Systems, Networking, Distributed Systems, Databases</p>
                </div>
                </FadeIn>
            </div>
        );
    }
}